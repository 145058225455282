import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-genaration-page',
  templateUrl: './password-genaration-page.component.html',
  styleUrls: ['./password-genaration-page.component.scss']
})
export class PasswordGenarationPageComponent implements OnInit {

  password = '';
  passwordLength = 0;
  useLetters = false;
  useSymbols = false;
  useNumbers = false;

  constructor() { }

  ngOnInit(): void {}

  onChangeLength(value: string) {
      const parsedValue = parseInt(value, 0);
      if (!isNaN(parsedValue)) {
        this.passwordLength = parsedValue;
      }
  }

  onChangeUseLetters(): void {
    this.useLetters = !this.useLetters;
  }

  onChangeUseSymbols(): void {
    this.useSymbols = !this.useSymbols;
  }

  onChangeUseNumbers(): void {
    this.useNumbers = !this.useNumbers;
  }

  generate(): void {

    const numbers = '1234567890';
    const letters = 'qwertyuiopasdfghjklzxcvbnm';
    const symbols = '!@#$%^&*()';

    let validChars = '';

    if (this.useLetters) {
      validChars += letters;
    }

    if (this.useNumbers) {
      validChars += numbers;
    }

    if (this.useSymbols) {
      validChars += symbols;
    }

    let generatedPassword = '';

    for (let i = 0; i < this.passwordLength; i += 1) {
      const index = Math.floor(Math.random() * validChars.length);
      generatedPassword += validChars[index];
    }

    this.password = generatedPassword;

  }

}
