import { Component } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, timeout} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  positionProp = 'left';
  widgetData = {
    isActive: false
  };

  resData$: Observable<any>;

  handleClick() {
    console.log('Click handled!');
  }

  ngOnInit() {

    const req1$ = of({text: 'Text 1'});
    const req2$ = of({text: 'Text 2'});

    forkJoin([req1$.pipe(timeout(5000)), req2$])
      .pipe(
        map((data) => {
        console.log('Data', data);
        return data;
      })
      ).subscribe(data => {
        console.log('Result data: ', data);
        this.resData$ = of(data);
      });
  }

}
