import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'custom-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input('text') text: string;
  @Output() clickEmt: EventEmitter<any> = new EventEmitter();


  ngOnInit(): void {}

  click() {
    this.clickEmt.emit();
  }

}
