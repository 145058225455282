
<div class="container">

  <div class="columns is-centered">
    <div class="box column is-one-third">
      <h1 class="title has-text-centered">Password generation !</h1>
      <div class="field">
        <label for="len">Length: </label>
        <input
          type="text"
          id="len"
          class="input is-rounded is-small"
          [value]="passwordLength"
          (input)="onChangeLength($event.target.value)"
        >
      </div>

      <div class="field">
        <div class="control">
          <label for="id3" class="checkbox">Use Letters</label>
          <input
            type="checkbox"
            id="id3"
            (change)="onChangeUseLetters()"
          >
        </div>
      </div>
      <div class="field">
        <div class="control">
          <label for="id1" class="checkbox">Use Symbols</label>
          <input
            type="checkbox"
            id="id1"
            (change)="onChangeUseSymbols()"
          >
        </div>
      </div>
      <div class="field">
        <div class="control">
          <label for="id2" class="checkbox">Use Numbers</label>
          <input
            type="checkbox"
            id="id2"
            [checked]="useNumbers"
            (change)="onChangeUseNumbers()"
          >
        </div>
      </div>


      <div class="generate-btn-wrap">
        <button
          class="button is-success is-outlined is-fullwidth"
          [disabled]="!(passwordLength && (useLetters || useNumbers || useSymbols))"
          (click)="generate()"
        >
          Generate
        </button>
      </div>

      <div class="box" *ngIf="password">
        <label for="generatedPass">Your Password</label>
        <input type="text" id="generatedPass" class="input" [value]="password">
      </div>
    </div>

  </div>

</div>

